<template>
<div class="form-row">
    <div class=" text-center col-md-12 pl-2 pr-2" v-if="vehicleFleetData.length> 0">
        <div class="row">
            <TogglePlayButton
                    @toggle-play="actionPlay"
                 />
                 <div class="col-md-10">
                    <h4 v-if="isPlaying" class="text-success  ">
                        Buscando localização.
                    </h4>
                 </div>
        </div>
 
        <!-- getPostionList -->
        <GmapMap 
         :center="getCenter" 
         :zoom="5" 
        style="width: 100%; height: 1000px" 
        @zoom_changed="handleZoomChanged" >

        <!-- <gmap-cluster> -->
          <GmapMarker
            v-for="(vehicle, index) in vehicleFleetData"
            v-if="vehicle.position"
            :key="index"
            :position=" getCoord(vehicle.position)"
            :icon="srcIcon(vehicle)"
            @click="setSelectedVehicleDetails(vehicle)"
          />
        <!-- </gmap-cluster> -->
           
             <GmapInfoWindow v-if="showInfoWindow === 'vehicle'" :position="markerPosition">
            <div>
              <vehicle-info :vehicle="selectedVehicleDetails" :vehicleDatalhe="vehicleDatalhe" />
            </div>
          </GmapInfoWindow>
           

        </GmapMap>
    </div>
    <div class="form-group col-md-12 pl-2 pr-2 text-center" v-else>
        <p class="badge badge-warning">{{ $t('location not specified')  }}</p>
        <CurrentLocationMap />
    </div>
</div>
</template>

<script>
const HAS_PIN = require("@/assets/images/resgate/pin_24.png")
import { RefreshCcwIcon } from 'vue-feather-icons'
import CurrentLocationMap from "./CurrentLocationMap";
import VehicleInfo from "./VehicleInfo";
import TogglePlayButton from "./TogglePlayButton";

export default {
    data() {
        return {
            latitude: 0,
            longitude: 0,
            showInfoWindow: null,
            selectedVehicleDetails: null,
            vehicleDatalhe: null,
            circleRadius: 1000, // Defina o raio do círculo em metros
            playInterval: 10000,
            indexMarker: null,
            isPlaying: false,
            markerPosition: {
                lat: 0,
                lng: 0
            },
            center: {
                lat: 0,
                lng: 0
            },

        }
    },
    props: {
        vehicleFleet: {
            type: Array,
            default: []
        },
        zoomMapa: {
            type: Number,
            default: 5
        },

    },
    computed: {
        getZoom(){
            return this.zoomMapa;

        },
        vehicleFleetData(){
            return this.vehicleFleet??[]
        },
        getCenter(){
            return this.center;

        }, 
        imagePinSrc() {
            return HAS_PIN;
        }

    },
    methods: {
        creatCenter(coord) {
            if (this.center.lat == 0 && coord ) {
                this.center = coord
            }
        },
        convertToLatLng(coord) {
        return { lat: parseFloat(coord.lat), lng: parseFloat(coord.lon) };
      },
      srcIcon(veiculo) {
            return veiculo.vehicle_image_url ?? null
        },
      getCoord(position) {
        if (position != null && position.coord){
            const coord = this.convertToLatLng(position.coord)
            this.creatCenter(coord);
            return coord
        }
        return { lat: 0,
            lng: 0}


      },
        toggleInfoWindow(marker, vehicleData) {
        this.showInfoWindow = this.showInfoWindow === marker ? null : marker;
        this.vehicleDatalhe = vehicleData
        const postionDevice = vehicleData.position?? null;
        
        if (postionDevice){
            this.markerPosition = this.convertToLatLng(postionDevice.coord)

            // selectedVehicleDetails = postionDevice
            this.selectedVehicleDetails ={ 
                BackBattery:postionDevice.BackBattery?? 0, 
                CPRDateTime:  postionDevice.CPRDateTime??"", 
                EDN2: postionDevice.EDN2??"", 
                EDN3: postionDevice.EDN3??"", 
                GSMStatus: postionDevice.GSMStatus??"", 
                IN1: postionDevice.IN1??"", 
                IN2: postionDevice.IN2??"", 
                IN3: postionDevice.IN3??"", 
                Ignition: postionDevice.Ignition??"", 
                MainBattery: postionDevice.MainBattery??0, 
                MainBatteryDec: postionDevice.MainBatteryDec?? 0, 
                MsgDateTime: postionDevice.MsgDateTime??"", 
                Panic: postionDevice.Panic??"", 
                SleepMode: postionDevice.SleepMode??"", 
                VehicleBattery: postionDevice.VehicleBattery??"", 
                VehicleBatteryEvent: postionDevice.VehicleBatteryEvent??"", 
                altitude: postionDevice.altitude??0, 
                angle: postionDevice.angle??0, 
                batCharge: postionDevice.batCharge??0, 
                cause: postionDevice.cause??"", 
                gps: postionDevice.gps??"", 
                imei: postionDevice.imei??'', 
                lat: postionDevice.coord.lat??'', 
                lon: postionDevice.coord.lon??'', 
                sats: postionDevice.sats??0, 
                speed: postionDevice.speed??0, 
                speed_limit: postionDevice.speed_limit??"", 
                speed_limit_100: postionDevice.speed_limit_100??"", 
                speed_limit_60: postionDevice.speed_limit_60??"", 
                speed_limit_80: postionDevice.speed_limit_80??"", 
                timestamp: postionDevice.timestamp??"", 
                timestampGateway: postionDevice.timestampGateway??""
             }
        }

      },
      
      actionPlay(is_playing){
            console.log('is_playing', is_playing)
            this.isPlaying = is_playing
            if(is_playing){
                this.center = {
                lat: 0,
                lng: 0
            };
                this.startPlay()
                return 
            }
            this.pausePlay()
        },
        startPlay() {
            
            this.isPlaying = true;
            this.animationInterval = setInterval(() => {
            if (this.isPlaying) {
              
                this.$emit('reload', true)
            }
            else {
                this.pausePlay(); // Pausar quando chegar ao final se o loop não estiver ativo
            }
            }, this.playInterval); // Usa o intervalo selecionado
        },
        pausePlay() {
            this.isPlaying = false;
            clearInterval(this.animationInterval);
        },
        init() {

        this.$emit('reload', true)
        },
        setSelectedVehicleDetails(vehicle) {
        this.selectedVehicleDetails = vehicle;
        this.showInfoWindow = 'vehicle';
        let position = vehicle.position?? null ;
        this.selectedVehicleDetails = position;
        this.markerPosition = this.convertToLatLng(position.coord)
        this.vehicleDatalhe= vehicle

        this.selectedVehicleDetails ={ 
                BackBattery:position.BackBattery?? 0, 
                CPRDateTime:  position.CPRDateTime??"", 
                EDN2: position.EDN2??"", 
                EDN3: position.EDN3??"", 
                GSMStatus: position.GSMStatus??"", 
                IN1: position.IN1??"", 
                IN2: position.IN2??"", 
                IN3: position.IN3??"", 
                Ignition: position.Ignition??"", 
                MainBattery: position.MainBattery??0, 
                MainBatteryDec: position.MainBatteryDec?? 0, 
                MsgDateTime: position.MsgDateTime??"", 
                Panic: position.Panic??"", 
                SleepMode: position.SleepMode??"", 
                VehicleBattery: position.VehicleBattery??"", 
                VehicleBatteryEvent: position.VehicleBatteryEvent??"", 
                altitude: position.altitude??0, 
                angle: position.angle??0, 
                batCharge: position.batCharge??0, 
                cause: position.cause??"", 
                gps: position.gps??"", 
                imei: position.imei??'', 
                lat: position.coord.lat??'', 
                lon: position.coord.lon??'', 
                sats: position.sats??0, 
                speed: position.speed??0, 
                speed_limit: position.speed_limit??"", 
                speed_limit_100: position.speed_limit_100??"", 
                speed_limit_60: position.speed_limit_60??"", 
                speed_limit_80: position.speed_limit_80??"", 
                timestamp: position.timestamp??"", 
                timestampGateway: position.timestampGateway??""
             }
      },
    handleZoomChanged(newZoom) {
        console.log('newZoom', newZoom)
        this.$emit('zoomChanged', newZoom)
    },
    markerClicked(index) {
      this.indexMarker = index;
    },

    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown)
        

    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    components: {
        RefreshCcwIcon,
        CurrentLocationMap,
        VehicleInfo,
        TogglePlayButton,

  }

};
</script>
