<template>
<div class="form-row">
    <div class="form-group col-md-12 pl-2 pr-2" v-if="centerProp">
        <div class="row">

            <!-- <div class="text-left m-1">
          <b-button  :variant=" !isPlaying ?'primary':'warning'" @click="actionPlay(!isPlaying)" >
            <b-spinner v-if="isPlaying" variant="primary" type="grow"   ></b-spinner>
            <refresh-ccw-icon  v-else size="1.9x"   class="success" ></refresh-ccw-icon>
           <strong style="min-width:350px; margin-top: -20px;">
               {{ isPlaying ? 'Parar': 'Atualizar'}}
           </strong> 
            <i  v-if="!isPlaying" class="fa fa-play"></i>
            <i v-else class="fa fa-pause"></i>
          </b-button>
        </div> -->
        <TogglePlayButton
            @toggle-play="actionPlay"
         />
        <div class="col-md-10">
            <div class="row  mb-n2 mt-2">
                <div class="col-3 text-center">

                    <h4 v-if="isPlaying" class="text-success  ">
                        Buscando localização.
                    </h4>
                </div>
                <div class="col-5 text-center">
                    <h5 > {{ $t('Notification date')}} {{getNotificationDate  }}</h5>
                </div>
    
            </div>
            </div>
        </div>

        <GmapMap @click="mapClicked" :center="center" :zoom="getZoom" style="width: 100%; height: 1000px"  @zoom_changed="handleZoomChanged" >
            <GmapMarker 
            :position="markerPosition" 
            @dragend="markerDragged" 
            draggable 
            :icon="srcIcon"  
            @click="toggleInfoWindow('vehicle')"/>
                       
             <GmapInfoWindow v-if="showInfoWindow === 'vehicle'" :position="markerPosition">
            <div>
              <vehicle-info :vehicle="selectedVehicleInfo" :vehicleDatalhe="vehicleDatalhe" />
            </div>
          </GmapInfoWindow>
            

        </GmapMap>
    </div>
    <div class="form-group col-md-12 pl-2 pr-2 text-center" v-else>
        <p class="badge badge-warning">{{ $t('location not specified')  }}</p>
        <CurrentLocationMap />
    </div>
</div>
</template>

<script>
const HAS_PIN = require("@/assets/images/resgate/pin_24.png")
import { RefreshCcwIcon } from 'vue-feather-icons'
import CurrentLocationMap from "./CurrentLocationMap";
import VehicleInfo from "./VehicleInfo";
import TogglePlayButton from "./TogglePlayButton";

export default {
    data() {
        return {
            latitude: 0,
            longitude: 0,
            zoomData: 18,
            showInfoWindow: null,
            selectedVehicleDetails: null,
            circleRadius: 1000, // Defina o raio do círculo em metros
            circleOptions: {
                fillColor: '#00FF00',
                fillOpacity: 0.35,
            },
            polygonCoordinatesData: [],
            indexMarker: null,
            isPlaying: false,
            playInterval: 10000,
            animationInterval: null,
            centerFrist: null,


        }
    },
    props: {
        vehicleDatalhe: Object,
        postionDevice: Object,
        centerProp: {
            type: Object,
            default: {
                lat: 0,
                lng: 0
            }
        },
        notificationDate:{
            type: String,
            default: ''
        },
        ray: {
            type: Number,
            default: 5
        },
        url: {
            type: String,
            default: ''
        },
        areaTypeId: {
            type: Number,
            default: 1

        },
        zoom: {
            type: Number,
            default: 13

        },
        polygonCoordinates: {
            type: Array,
            default: []
        }

    },
    computed: {
        getZoom(){
            return this.zoomData;

        },
        getNotificationDate(){
            return this.notificationDate;

        },
        isCircl() {
            return this.areaTypeId === 1
        },
        center() {
            if (!this.centerFrist){
                this.centerFrist = this.centerProp
            }
            return this.centerFrist

        },
        srcIcon() {
            return this.url ?? null
        },
        markerPosition() {
            return this.centerProp
        },
        circleCenter() {
            return this.centerProp
        },
        rayKm() {
            return Number(this.ray) ?? 5
        },
        polygonCoordinatesNow() {
            return this.polygonCoordinatesData;
        },
        imagePinSrc() {
            return HAS_PIN;
        },
        updateInfo(){
            return this.postionDevice?? null

        },
        selectedVehicleInfo(){
            if (this.updateInfo){
            return { 
                BackBattery:this.updateInfo.BackBattery?? 0, 
                CPRDateTime:  this.updateInfo.CPRDateTime??"", 
                EDN2: this.updateInfo.EDN2??"", 
                EDN3: this.updateInfo.EDN3??"", 
                GSMStatus: this.updateInfo.GSMStatus??"", 
                IN1: this.updateInfo.IN1??"", 
                IN2: this.updateInfo.IN2??"", 
                IN3: this.updateInfo.IN3??"", 
                Ignition: this.updateInfo.Ignition??"", 
                MainBattery: this.updateInfo.MainBattery??0, 
                MainBatteryDec: this.updateInfo.MainBatteryDec?? 0, 
                MsgDateTime: this.updateInfo.MsgDateTime??"", 
                Panic: this.updateInfo.Panic??"", 
                SleepMode: this.updateInfo.SleepMode??"", 
                VehicleBattery: this.updateInfo.VehicleBattery??"", 
                VehicleBatteryEvent: this.updateInfo.VehicleBatteryEvent??"", 
                altitude: this.updateInfo.altitude??0, 
                angle: this.updateInfo.angle??0, 
                batCharge: this.updateInfo.batCharge??0, 
                cause: this.updateInfo.cause??"", 
                gps: this.updateInfo.gps??"", 
                imei: this.updateInfo.imei??'', 
                lat: this.updateInfo.coord.lat??'', 
                lon: this.updateInfo.coord.lon??'', 
                sats: this.updateInfo.sats??0, 
                speed: this.updateInfo.speed??0, 
                speed_limit: this.updateInfo.speed_limit??"", 
                speed_limit_100: this.updateInfo.speed_limit_100??"", 
                speed_limit_60: this.updateInfo.speed_limit_60??"", 
                speed_limit_80: this.updateInfo.speed_limit_80??"", 
                timestamp: this.updateInfo.timestamp??"", 
                timestampGateway: this.updateInfo.timestampGateway??""
             }
             
            }
            return null
        },

    },
    methods: {
        toggleInfoWindow(marker) {
        this.showInfoWindow = this.showInfoWindow === marker ? null : marker;
        if (this.updateInfo){
            // this.selectedVehicleDetails = this.postionDevice
            this.selectedVehicleDetails ={ 
                BackBattery:this.updateInfo.BackBattery?? 0, 
                CPRDateTime:  this.updateInfo.CPRDateTime??"", 
                EDN2: this.updateInfo.EDN2??"", 
                EDN3: this.updateInfo.EDN3??"", 
                GSMStatus: this.updateInfo.GSMStatus??"", 
                IN1: this.updateInfo.IN1??"", 
                IN2: this.updateInfo.IN2??"", 
                IN3: this.updateInfo.IN3??"", 
                Ignition: this.updateInfo.Ignition??"", 
                MainBattery: this.updateInfo.MainBattery??0, 
                MainBatteryDec: this.updateInfo.MainBatteryDec?? 0, 
                MsgDateTime: this.updateInfo.MsgDateTime??"", 
                Panic: this.updateInfo.Panic??"", 
                SleepMode: this.updateInfo.SleepMode??"", 
                VehicleBattery: this.updateInfo.VehicleBattery??"", 
                VehicleBatteryEvent: this.updateInfo.VehicleBatteryEvent??"", 
                altitude: this.updateInfo.altitude??0, 
                angle: this.updateInfo.angle??0, 
                batCharge: this.updateInfo.batCharge??0, 
                cause: this.updateInfo.cause??"", 
                gps: this.updateInfo.gps??"", 
                imei: this.updateInfo.imei??'', 
                lat: this.updateInfo.coord.lat??'', 
                lon: this.updateInfo.coord.lon??'', 
                sats: this.updateInfo.sats??0, 
                speed: this.updateInfo.speed??0, 
                speed_limit: this.updateInfo.speed_limit??"", 
                speed_limit_100: this.updateInfo.speed_limit_100??"", 
                speed_limit_60: this.updateInfo.speed_limit_60??"", 
                speed_limit_80: this.updateInfo.speed_limit_80??"", 
                timestamp: this.updateInfo.timestamp??"", 
                timestampGateway: this.updateInfo.timestampGateway??""
             }
        }

      },
        actionPlay(is_playing){
            console.log('is_playing', is_playing)
            if(is_playing){
                this.startPlay()
                return 
            }
            this.pausePlay()
        },
        startPlay() {
            this.isPlaying = true;
            this.animationInterval = setInterval(() => {
            if (this.isPlaying) {
                this.$emit('reload', true)
            }
            else {
                this.pausePlay(); // Pausar quando chegar ao final se o loop não estiver ativo
            }
            }, this.playInterval); // Usa o intervalo selecionado
        },
        pausePlay() {
            this.isPlaying = false;
            clearInterval(this.animationInterval);
        },
            
        init() {
        this.$emit('reload', true)
        },
        handleZoomChanged(newZoom) {

        this.$emit('zoomChanged', newZoom)
        },
        mapClicked(event) {
            // this.markerPosition = {
            //     lat: event.latLng.lat(),
            //     lng: event.latLng.lng(),
            // };
            if (this.isCircl) {
                this.latitude = event.latLng.lat();
                this.longitude = event.latLng.lng();
                this.circleCenter = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
            }
            if (!this.isCircl) {

                this.polygonCoordinatesData.push({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                })
                this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            }

        },
        markerPolygonCoordinatesChangeDragged(event, index) {

            this.polygonCoordinatesData[index] = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            }
            this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            this.polygonCoordinatesData = this.polygonCoordinates
            this.indexMarker = null;
        },
        markerPolygonCoordinatesChangeDblclick(event, index) {
           this.deleteMarker(index)
        },
        deleteMarker(index){
            this.polygonCoordinatesData.splice(index, 1)
            this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            // this.polygonCoordinatesData = this.polygonCoordinates 
            this.indexMarker = null;
        },
        markerDragged(event) {
            this.markerPosition = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            this.latitude = event.latLng.lat();
            this.longitude = event.latLng.lng();
            this.circleCenter = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };

            if (this.isCircl) {
                this.$emit('circleCenterChange', this.circleCenter);
            }
        },
        handleKeyDown(event) {
        this.isPlaying =false;
      if (event.key === 'Delete') {
        if(this.indexMarker != null) {
            this.deleteMarker(this.indexMarker)
        }
        
      }
    },
    markerClicked(index) {
      this.indexMarker = index;
    },

    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown)
        this.polygonCoordinatesData = this.polygonCoordinates
        this.zoomData = this.zoom
        

    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    components: {
        RefreshCcwIcon,
        CurrentLocationMap,
        VehicleInfo,
        TogglePlayButton
  }

};
</script>
