<template>
    <div class="vehicle-info">
      <!-- Informações do veículo -->
      <div class="vehicle-details">
        <h3>Veículo: {{ vehicleDatalhe.vehicle }}</h3>
        <ul>
          <li>Placa: {{ vehicleDatalhe.license_plate }}</li>
          <li>Tipo: {{ vehicleDatalhe.sigla }}</li>
          <li>Unidade: {{ vehicleDatalhe.unit }}</li>
        </ul>
      </div>
      <div class="row">
        <div class="col-12">
          <!-- Situação dos Sensores -->
          <div class="sensor-status">
          <b-button block variant="primary" @click="toggleSensors" >
            {{ showSensors ? "Ocultar situação dos Sensores" : "Mostrar situação dos Sensores" }}
          </b-button>
          <div v-if="showSensors" class="text-center">
            <ul>
              <li>
                Bateria do veículo:
                <span :class="getStatusClass(sensors.battery)"></span>
              </li>
              <li>
                Capô fechado:
                <span :class="getStatusClass(sensors.hood)"></span>
              </li>
              <li>
                Comunicação:
                <span :class="getStatusClass(sensors.communication)"></span>
              </li>
              <li>
                Em deslocamento:
                <span :class="getStatusClass(sensors.moving)"></span>
              </li>
              <li>
                Ignição:
                <span :class="getStatusClass(sensors.ignition)"></span>
              </li>
              <li>Nível da bateria: {{ sensors.batteryLevel }}</li>
              <li>
                Porta do motorista fechada:
                <span :class="getStatusClass(sensors.driverDoor)"></span>
              </li>
              <li>
                Porta do passageiro fechada:
                <span :class="getStatusClass(sensors.passengerDoor)"></span>
              </li>
              <li>
                Sinal de GPS:
                <span :class="getStatusClass(sensors.gpsSignal)"></span>
              </li>
              <li>
                Velocidade acima de 100 km/h:
                <span :class="getStatusClass(sensors.speedOver100)"></span>
              </li>
              <li>
                Velocidade acima de 60 km/h:
                <span :class="getStatusClass(sensors.speedOver60)"></span>
              </li>
              <li>
                Velocidade acima de 80 km/h:
                <span :class="getStatusClass(sensors.speedOver80)"></span>
              </li>
              <li>
                Velocidade limite excedida:
                <span :class="getStatusClass(sensors.speedLimitExceeded)"></span>
              </li>
            </ul>
          </div>
        </div>

        </div>
        <div class="col-12">
          <!-- Informações do GPS -->
          <div class="gps-info">
            <b-button block variant="primary" @click="toggleGpsInfo" >
              {{ showGpsInfo ? "Ocultar informações do GPS" : "Mostrar informações do GPS" }}
            </b-button>
            <div v-if="showGpsInfo">
              <ul>
                <li>Posição: {{ gps.position }}</li>
                <li>Data do GPS: {{ convertToLocalTime(gps.date) }}</li>
                <li>Data de Envio: {{ convertToLocalTime(gps.sentDate) }}</li>
                <li>Satélites: {{ gps.satellites }}</li>
                <li>Velocidade: {{ gps.speed }} Km/h</li>
                <li>Altitude: {{ gps.altitude }} Metros</li>
                <li>Azimute: {{ gps.azimuth }}°</li>
              </ul>
            </div>
          </div>

        </div>

       </div>
      
  
      
    </div>
    
  </template>
  
  <script>
  export default {
    name: "VehicleInfo",
    props:{
        vehicle: Object,
        vehicleDatalhe: Object,
    },
    data() {
      return {
        showSensors: true,
        showGpsInfo: true,
      };
    },
    computed: {
      gps(){
        return {
          position: `${this.vehicle.lat??''},${this.vehicle.lon??''}`,
          date: this.vehicle.timestamp,
          sentDate: this.vehicle.timestampGateway,
          satellites: this.vehicle.sats,
          speed: this.vehicle.speed,
          altitude: this.vehicle.altitude,
          azimuth: this.vehicle.angle,
        }
      },
   
      sensors(){
        return {
          battery: this.vehicle.VehicleBattery == "ON"? false:true,
          hood: this.vehicle.Panic == "ON" || this.vehicle.IN3 == "ON" ? false:true,
          communication: this.vehicle.GSMStatus == "ON"? true:false,
          moving: this.vehicle.SleepMode == "ON"? false:true,
          ignition: this.vehicle.Ignition =="OFF"? false:true,
          batteryLevel: `${this.getMainBatteryDec(this.vehicle.MainBatteryDec)} V`,
          driverDoor: this.vehicle.EDN2=="ON" || this.vehicle.IN1=="ON"? false:true,
          passengerDoor: this.vehicle.EDN3=="ON" || this.vehicle.IN2=="ON"? false:true,
          gpsSignal: this.vehicle.gps =="NOFIX"? false:true,
          speedOver100:this.vehicle.speed_limit_100 == "OFF"? true:false,
          speedOver60:this.vehicle.speed_limit_60 == "OFF"? true:false,
          speedOver80: this.vehicle.speed_limit_80 == "OFF"? true:false,
          speedLimitExceeded: this.vehicle.speed_limit == "OFF"? true:false,
        }
      
      }

    },
    methods: {
      getMainBatteryDec(mainBattery) {
      return mainBattery > 100 ? (mainBattery / 10).toFixed(1) : mainBattery;
    },
      toggleSensors() {
        this.showSensors = !this.showSensors;
      },
      toggleGpsInfo() {
        this.showGpsInfo = !this.showGpsInfo;
      },
      getStatusClass(status) {
        return status ? "status-green" : "status-red";
      },
      convertToLocalTime(gmtDate) {
        console.log('gmtDate', gmtDate)
        if(gmtDate) {
          // Converte a entrada para o formato ISO usando destructuring
          const [datePart, timePart] = gmtDate.split(" ");
          const [year, month, day] = datePart.split("-");
          const [hours, minutes, seconds] = timePart.split(":");

          // Cria um objeto Date no formato ISO
          const gmtDateObject = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`);

          // Verifica se a data é válida
          if (isNaN(gmtDateObject.getTime())) throw new Error("Data inválida");

          // Formata a data final
          return `${String(gmtDateObject.getDate()).padStart(2, "0")}/` +
              `${String(gmtDateObject.getMonth() + 1).padStart(2, "0")}/` +
              `${gmtDateObject.getFullYear()} ` +
              `${String(gmtDateObject.getHours()).padStart(2, "0")}:` +
              `${String(gmtDateObject.getMinutes()).padStart(2, "0")}:` +
              `${String(gmtDateObject.getSeconds()).padStart(2, "0")}`;
        }
        return gmtDate
        },
    },
  };
  </script>
  
  <style scoped>
  .vehicle-info {
    font-family: Arial, sans-serif;
    font-size: larger;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    max-width: 600px;
    background-color: #f9f9f9;
  }
  
  .vehicle-details h3 {
    margin: 0;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin: 5px 0;
  }
  
  .status-green::before {
    content: "🟢";
  }
  
  .status-red::before {
    content: "🔴";
  }
  
  button {
    margin: 10px 0;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  