<template>
    <div class="play-button text-left m-1">
      <b-button :variant="isPlaying ? 'warning' : 'info'" @click="togglePlay">
        <template v-if="isPlaying">
          <b-spinner  type="grow" class="me-2 mr-1"></b-spinner>
          <!-- <strong class="button-text ml-1 mr-3">Parar</strong> -->
          <i class="fa fa-pause ms-2"></i>
        </template>
        <template v-else>
          <refresh-ccw-icon size="1.9x" class="icon-refresh me-2 mr-1"></refresh-ccw-icon>
          <!-- <strong class="button-text ml-1 mr-1">Atualizar</strong> -->
          <i class="fa fa-play ms-2"></i>
        </template>
      </b-button>
    </div>
  </template>
  
  <script>
  import { RefreshCcwIcon } from 'vue-feather-icons'

  export default {
    data() {
      return {
        isPlaying: false,
      
      };
    },
    methods: {
      togglePlay() {
        this.isPlaying = !this.isPlaying;
        this.$emit('toggle-play', this.isPlaying);
      },
    },
    components: {
      RefreshCcwIcon,
    }
  };
  </script>
  
  <style scoped>
  .play-button {
    display: flex;
    align-items: center;
  }
  
  .button-text {
    min-width: 150px;
  }
  
  .icon-refresh {
    color: #FFF;
  }
  
  b-button {
    display: flex;
    align-items: center;
  }
  </style>
  