<template>
  <div class="form-row">
    <div class="form-group col-md-12 pl-2 pr-2" v-if="centerProp">
      <div class="row">
        <div class="col-md-10 text-center m-1">
          <h5> {{ $t('Notification date') }} {{ getNotificationDate }}</h5>
        </div>
      </div>

      <!-- Mapa com Marcadores dos Veículos -->
      <GmapMap
        @click="mapClicked"
        :center="center"
        :zoom="getZoom"
        style="width: 100%; height: 500px"
        @zoom_changed="handleZoomChanged"
      >
        <!-- Marcador do Centro -->
        <GmapMarker
          :position="center"
          :icon="centerIcon"
          draggable
          @dragend="centerMarkerDragged"
        />

        <!-- Marcadores dos Veículos -->
        <GmapMarker
          v-for="(vehicle, index) in vehicles"
          :key="vehicle.id"
          :position="vehicle.position"
          :icon="vehicle.icon"
          @click="vehicleClicked(vehicle)"
          @dragend="vehicleDragged($event, index)"
          @mouseover="showInfoWindow(vehicle)"
          @mouseout="hideInfoWindow"
          draggable
        >
          <!-- InfoWindow para exibir nome e placa do veículo ao passar o mouse -->
          <GmapInfoWindow v-if="hoveredVehicle && hoveredVehicle.id === vehicle.id" :options="{ pixelOffset: { width: 0, height: -35 } }">
            <div>
              <strong>{{ vehicle.name }}</strong><br />
              Placa: {{ vehicle.license_plate }}<br />
              Lat: {{ vehicle.position.lat }}<br />
              Lng: {{ vehicle.position.lng }}<br />
              {{ $t('Notification date')}} {{formatData(vehicle.lastsampletime)}}<br />
              
            </div>
          </GmapInfoWindow>
        </GmapMarker>
      </GmapMap>
    </div>

    <!-- Mensagem quando a localização não está especificada -->
    <div class="form-group col-md-12 pl-2 pr-2 text-center" v-else>
      <!-- <p class="badge badge-warning">{{ $t('location not specified') }}</p> -->
      <p class="badge badge-warning">Não há veículos a serem exibidos</p>
      <CurrentLocationMap />
    </div>
  </div>
</template>

<script>
import HAS_PIN from '@/assets/images/resgate/pin_24.png';
import CurrentLocationMap from "./CurrentLocationMap";
import VehicleInfo from "./VehicleInfo";

export default {
  props: {
    centerProp: {
      type: Object,
      default: () => ({ lat: 0, lng: 0 }),
    },
    vehicles: {
      type: Array,
      required: true,
    },
    notificationDate: {
      type: String,
      default: '',
    },
    ray: {
      type: Number,
      default: 5,
    },
    areaTypeId: {
      type: Number,
      default: 1,
    },
    zoom: {
      type: Number,
      default: 13,
    },
    polygonCoordinates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      zoomData: this.zoom,
      circleRadius: this.ray * 1000, // Converte o raio para metros
      circleOptions: {
        fillColor: '#00FF00',
        fillOpacity: 0.35,
      },
      polygonOptions: {
        fillColor: '#FF0000',
        fillOpacity: 0.2,
      },
      selectedVehicle: null,
      hoveredVehicle: null, // Armazena o veículo atualmente sendo "hovered"
    };
  },
  computed: {
    getZoom() {
      return this.zoomData;
    },
    getNotificationDate() {
      return this.notificationDate;
    },
    isCircle() {
      return this.areaTypeId === 1;
    },
    center() {
      return this.centerProp;
    },
    circleCenter() {
      return this.centerProp;
    },
    centerIcon() {
      return this.centerProp.icon || HAS_PIN;
    },
  },
  methods: {
    init() {
      this.$emit('reload', true);
    },
    formatData(data) {
      return moment(`${data}`).format('DD/MM/YYYY HH:mm:ss')
    },
    handleZoomChanged(newZoom) {
      this.$emit('zoomChanged', newZoom);
      this.zoomData = newZoom;
    },
    mapClicked(event) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.$emit('mapClicked', { lat, lng });
    },
    vehicleClicked(vehicle) {
      this.selectedVehicle = vehicle;
      this.$emit('vehicleClicked', vehicle);
    },
    showInfoWindow(vehicle) {
      // Define o veículo "hovered" para mostrar a InfoWindow
      this.hoveredVehicle = vehicle;
    },
    hideInfoWindow() {
      // Limpa o veículo "hovered" para esconder a InfoWindow
      this.hoveredVehicle = null;
    },
    vehicleDragged(event, index) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.$emit('vehicleDragged', { index, lat, lng });
    },
    centerMarkerDragged(event) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.$emit('centerMarkerDragged', { lat, lng });
    },
  },
  components: {
    CurrentLocationMap,
    VehicleInfo,
  },
};
</script>

<style scoped>
</style>
