<template>
<div class="form-row">
    <div class="form-group col-md-12 pl-2 pr-2" v-if="centerProp && centerProp.lat ">
        <div class="row">

            <div class="text-left m-1">
                <b-button size="sm" @click="init" variant="outline-info" class="rounded-circle p-1" v-b-tooltip.hover :title=" $t('Search')" >
                    <refresh-ccw-icon size="1.5x" class="custom-class"></refresh-ccw-icon>
              </b-button>
    
            </div>
            <div class="col-md-10 text-center m-1">
    
              <h5> {{ $t('Notification date')}} {{getNotificationDate  }}</h5>
            </div>
        </div>

        <GmapMap @click="mapClicked" :center="center" :zoom="getZoom" style="width: 100%; height: 1000px"  @zoom_changed="handleZoomChanged" >
            <GmapMarker :position="markerPosition" @dragend="markerDragged" draggable :icon="srcIcon"  @click="toggleInfoWindow('vehicle')"/>
           
            <GmapMarker v-if="!isCircl" v-for="(position, index) in polygonCoordinatesNow" :key="index" :position="position" 
            :icon="imagePinSrc" @dragend="(event)=>markerPolygonCoordinatesChangeDragged(event,index)" draggable
            @click="markerClicked(index)"
             @dblclick="(event)=>markerPolygonCoordinatesChangeDblclick(event,index)" />
             <GmapInfoWindow v-if="showInfoWindow === 'vehicle'" :position="markerPosition">
            <div>
              <vehicle-info :vehicle="selectedVehicleDetails" :vehicleDatalhe="vehicleDatalhe" />
            </div>
          </GmapInfoWindow>
            <GmapCircle v-if="isCircl" :center="circleCenter" :radius="rayKm * 1000" :options="circleOptions" />
            <GmapPolygon v-else :paths="polygonCoordinatesNow" :options="circleOptions"></GmapPolygon>

        </GmapMap>
    </div>
    <div class="form-group col-md-12 pl-2 pr-2 text-center" v-else>
        <p class="badge badge-warning">{{ $t('location not specified')  }}</p>
        <CurrentLocationMap />
    </div>
</div>
</template>

<script>
const HAS_PIN = require("@/assets/images/resgate/pin_24.png")
import { RefreshCcwIcon } from 'vue-feather-icons'
import CurrentLocationMap from "./CurrentLocationMap";
import VehicleInfo from "./VehicleInfo";

export default {
    data() {
        return {
            latitude: 0,
            longitude: 0,
            zoomData: 18,
            showInfoWindow: null,
            selectedVehicleDetails: null,
            circleRadius: 1000, // Defina o raio do círculo em metros
            circleOptions: {
                fillColor: '#00FF00',
                fillOpacity: 0.35,
            },
            polygonCoordinatesData: [],
            indexMarker: null,

        }
    },
    props: {
        vehicleDatalhe: Object,
        postionDevice: Object,
        centerProp: {
            type: Object,
            default: {
                lat: 0,
                lng: 0
            }
        },
        notificationDate:{
            type: String,
            default: ''
        },
        ray: {
            type: Number,
            default: 5
        },
        url: {
            type: String,
            default: ''
        },
        areaTypeId: {
            type: Number,
            default: 1

        },
        zoom: {
            type: Number,
            default: 13

        },
        polygonCoordinates: {
            type: Array,
            default: []
        }

    },
    computed: {
        getZoom(){
            return this.zoomData;

        },
        getNotificationDate(){
            return this.notificationDate;

        },
        isCircl() {
            return this.areaTypeId === 1
        },
        center() {
            return this.centerProp

        },
        srcIcon() {
            return this.url ?? null
        },
        markerPosition() {
            return this.centerProp
        },
        circleCenter() {
            return this.centerProp
        },
        rayKm() {
            return Number(this.ray) ?? 5
        },
        polygonCoordinatesNow() {
            return this.polygonCoordinatesData;
        },
        imagePinSrc() {
            return HAS_PIN;
        }

    },
    methods: {
        toggleInfoWindow(marker) {
        this.showInfoWindow = this.showInfoWindow === marker ? null : marker;
        if (this.postionDevice){
            // this.selectedVehicleDetails = this.postionDevice
            this.selectedVehicleDetails ={ 
                BackBattery:this.postionDevice.BackBattery?? 0, 
                CPRDateTime:  this.postionDevice.CPRDateTime??"", 
                EDN2: this.postionDevice.EDN2??"", 
                EDN3: this.postionDevice.EDN3??"", 
                GSMStatus: this.postionDevice.GSMStatus??"", 
                IN1: this.postionDevice.IN1??"", 
                IN2: this.postionDevice.IN2??"", 
                IN3: this.postionDevice.IN3??"", 
                Ignition: this.postionDevice.Ignition??"", 
                MainBattery: this.postionDevice.MainBattery??0, 
                MainBatteryDec: this.postionDevice.MainBatteryDec?? 0, 
                MsgDateTime: this.postionDevice.MsgDateTime??"", 
                Panic: this.postionDevice.Panic??"", 
                SleepMode: this.postionDevice.SleepMode??"", 
                VehicleBattery: this.postionDevice.VehicleBattery??"", 
                VehicleBatteryEvent: this.postionDevice.VehicleBatteryEvent??"", 
                altitude: this.postionDevice.altitude??0, 
                angle: this.postionDevice.angle??0, 
                batCharge: this.postionDevice.batCharge??0, 
                cause: this.postionDevice.cause??"", 
                gps: this.postionDevice.gps??"", 
                imei: this.postionDevice.imei??'', 
                lat: this.postionDevice.coord.lat??'', 
                lon: this.postionDevice.coord.lon??'', 
                sats: this.postionDevice.sats??0, 
                speed: this.postionDevice.speed??0, 
                speed_limit: this.postionDevice.speed_limit??"", 
                speed_limit_100: this.postionDevice.speed_limit_100??"", 
                speed_limit_60: this.postionDevice.speed_limit_60??"", 
                speed_limit_80: this.postionDevice.speed_limit_80??"", 
                timestamp: this.postionDevice.timestamp??"", 
                timestampGateway: this.postionDevice.timestampGateway??""
             }
        }

      },
        init() {

        this.$emit('reload', true)
        },
        handleZoomChanged(newZoom) {

        this.$emit('zoomChanged', newZoom)
        },
        mapClicked(event) {
            // this.markerPosition = {
            //     lat: event.latLng.lat(),
            //     lng: event.latLng.lng(),
            // };
            if (this.isCircl) {
                this.latitude = event.latLng.lat();
                this.longitude = event.latLng.lng();
                this.circleCenter = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
            }
            if (!this.isCircl) {

                this.polygonCoordinatesData.push({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                })
                this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            }

        },
        markerPolygonCoordinatesChangeDragged(event, index) {

            this.polygonCoordinatesData[index] = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            }
            this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            this.polygonCoordinatesData = this.polygonCoordinates
            this.indexMarker = null;
        },
        markerPolygonCoordinatesChangeDblclick(event, index) {
           this.deleteMarker(index)
        },
        deleteMarker(index){
            this.polygonCoordinatesData.splice(index, 1)
            this.$emit('polygonCoordinatesChange', this.polygonCoordinatesData)
            // this.polygonCoordinatesData = this.polygonCoordinates 
            this.indexMarker = null;
        },
        markerDragged(event) {
            this.markerPosition = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            this.latitude = event.latLng.lat();
            this.longitude = event.latLng.lng();
            this.circleCenter = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };

            if (this.isCircl) {
                this.$emit('circleCenterChange', this.circleCenter);
            }
        },
        handleKeyDown(event) {
      if (event.key === 'Delete') {
        if(this.indexMarker != null) {
            this.deleteMarker(this.indexMarker)
        }
        
      }
    },
    markerClicked(index) {
      this.indexMarker = index;
    },

    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown)
        this.polygonCoordinatesData = this.polygonCoordinates
        this.zoomData = this.zoom
        

    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },
    components: {
        RefreshCcwIcon,
        CurrentLocationMap,
        VehicleInfo,
  }

};
</script>
